import React from 'react';


const SpotifyPlaylist = () => {
  return (
    <div>
<iframe src="https://open.spotify.com/embed/playlist/3o6ymQ30LyOj3KV6gsVTBc?utm_source=generator" width="100%" height="530" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </div>
  );
};

export default SpotifyPlaylist;